import { observer } from "mobx-react-lite";

import {
  CardType,
  CardTypeRegular,
  MasterLinkType,
} from "../../shared/models/Card";
import { ModalType } from "../../stores/ModalStore";
import { CardItemLocation } from "../CardItem/CardItem";
import {
  CardActionContextMenuDriver,
  CardItemActionContextMenu,
} from "../CardItemActionContextMenu/CardItemActionContextMenu";
import { LayoutDriver } from "../CardItemArticle/CardItemArticle";
import {
  CardItemEditableContextMenu,
  CardItemEditableContextMenuDriver,
} from "../CardItemEditable/CardItemEditableContextMenu";
import {
  CardInfoContextMenuDriver,
  CardItemInfoContextMenu,
} from "../CardItemInfoContextMenu/CardItemInfoContextMenu";
import { ContextMenuCardDelete } from "./ContextMenuCardDelete";
import ContextMenuCardEdit from "./ContextMenuCardEdit";
import { ContextMenuCardLayout } from "./ContextMenuCardLayout";
import { ContextMenuCardOrder } from "./ContextMenuCardOrder";
import { ContextMenuCardReport } from "./ContextMenuCardReport";
import { ContextMenuCardSave } from "./ContextMenuCardSave";
import { ContextMenuCardShare } from "./ContextMenuCardShare";
import ContextMenuCardSize from "./ContextMenuCardSize";
import { ContextMenuLayout } from "./ContextMenuLayout";

export interface CardProfileContextDriver {
  readonly isCardDeleteOptionVisible: boolean;
  readonly isCardSingleOptionActive: boolean;
  readonly isCardDoubleOptionActive: boolean;
  readonly isCardHeaderOptionActive: boolean;
  readonly adminOrOwner: boolean;
  readonly id: string;
  readonly contextMenuActive: boolean;
  readonly cardType: MasterLinkType;
  readonly deckPathString: string;
  readonly isCardOwner: boolean;
  readonly isCardArtical: boolean;
  readonly isCardLayoutEditorial: boolean;
  readonly isCardLayoutPhoto: boolean;
  readonly isCardLayoutText: boolean;
  readonly contentType: CardTypeRegular;
  readonly isAutoplayedCard: boolean;
  readonly isPersonalizeCard: boolean;
  readonly shouldRenderCardOrder: boolean;
  readonly cardOrderInput: string | null;
  readonly sortNumberInputError: string | null;
  onCardOrderChanged(order: string): void;
  onCardOrderBlured(): void;
  onChangeCardLayoutEditorial(): void;
  onChangeCardLayoutPhoto(): void;
  onChangeCardLayoutText(): void;
  onIconMoreClicked(): void;
  openContextMenu(): void;
  closeContextMenu(): void;
  toggleShareActive(): void;
  updateCardDelete(): void;
  onResizeCardDouble(): void;
  onResizeCardSingle(): void;
  onPinFeaturedCard(): void;
  onEditCard(): void;
  cardRepostIdUpdate(): void;
  openModal(modalType: ModalType): void;
}

export type CardDeckDetailContextMenuDriver =
  | CardProfileContextDriver
  | CardActionContextMenuDriver
  | CardInfoContextMenuDriver
  | CardItemEditableContextMenuDriver;

export interface CardDeckDetailContextMenuProps {
  driver: CardDeckDetailContextMenuDriver;
  layoutDriver: LayoutDriver;
  location: CardItemLocation;
  isCardSizeOptionVisible?: boolean;
  isHeaderCard?: boolean;
  isWrapperCardRepost?: boolean;
}

export const CardDeckDetailContextMenu = observer(
  function CardDeckDetailContextMenu(props: CardDeckDetailContextMenuProps) {
    const {
      driver,
      isCardSizeOptionVisible,
      layoutDriver,
      location,
      isHeaderCard,
      isWrapperCardRepost,
    } = props;

    if (driver.contentType === CardType.ACTION) {
      return <CardItemActionContextMenu driver={driver} />;
    }
    if (driver.contentType === CardType.INFO) {
      return <CardItemInfoContextMenu driver={driver} />;
    }
    if (driver.contentType === CardType.EDITABLE) {
      return <CardItemEditableContextMenu driver={driver} />;
    }

    if (driver.cardType === MasterLinkType.REMOVED) {
      return (
        <ContextMenuLayout
          driver={driver}
          layoutDriver={layoutDriver}
          location={location}
          isHeaderCard={isHeaderCard}
          renderContextMenuItem={() => (
            <ContextMenuCardDelete
              onDelete={() => {
                driver.closeContextMenu();
                driver.updateCardDelete();
                driver.openModal("cardDelete");
              }}
            />
          )}
        />
      );
    }

    if (driver.contentType === CardType.DECK_REPOST) {
      if (driver.adminOrOwner) {
        return (
          <ContextMenuLayout
            driver={driver}
            layoutDriver={layoutDriver}
            location={location}
            isHeaderCard={isHeaderCard}
            isWrapperCardRepost={isWrapperCardRepost}
            renderContextMenuItem={() => (
              <>
                {driver.shouldRenderCardOrder && (
                  <ContextMenuCardOrder
                    sortNumber={driver.cardOrderInput || ""}
                    onChanged={driver.onCardOrderChanged}
                    onBlured={driver.onCardOrderBlured}
                    sortNumberInputError={driver.sortNumberInputError}
                  />
                )}
                <ContextMenuCardDelete
                  onDelete={() => {
                    driver.closeContextMenu();
                    driver.updateCardDelete();
                    driver.openModal("cardDelete");
                  }}
                />
              </>
            )}
          />
        );
      }
      return (
        <ContextMenuLayout
          driver={driver}
          layoutDriver={layoutDriver}
          location={location}
          isHeaderCard={isHeaderCard}
          isWrapperCardRepost={isWrapperCardRepost}
          renderContextMenuItem={() => (
            <ContextMenuCardReport
              data-cy="CardDeckDetailContextMenu-CardReport"
              onReport={() => {
                driver.closeContextMenu();
                driver.openModal("cardReport");
              }}
            />
          )}
        />
      );
    }

    return (
      <ContextMenuLayout
        driver={driver}
        layoutDriver={layoutDriver}
        location={location}
        isHeaderCard={isHeaderCard}
        renderContextMenuItem={() => (
          <>
            {isCardSizeOptionVisible && driver.adminOrOwner ? (
              <>
                <ContextMenuCardSize driver={driver} />
                {driver.isCardArtical ? (
                  <ContextMenuCardLayout driver={driver} />
                ) : null}
              </>
            ) : null}

            {driver.adminOrOwner ? (
              <>
                {driver.shouldRenderCardOrder && (
                  <ContextMenuCardOrder
                    sortNumber={driver.cardOrderInput || ""}
                    onChanged={driver.onCardOrderChanged}
                    onBlured={driver.onCardOrderBlured}
                    sortNumberInputError={driver.sortNumberInputError}
                  />
                )}
                {!driver.isAutoplayedCard && (
                  <ContextMenuCardEdit
                    data-cy="CardDeckDetailContextMenu-CardEdit"
                    onEdit={() => {
                      driver.closeContextMenu();
                      driver.onEditCard();
                    }}
                  />
                )}
                <ContextMenuCardShare
                  data-cy="CardDeckDetailContextMenu-CardShare"
                  onShare={() => {
                    driver.toggleShareActive();
                    driver.closeContextMenu();
                  }}
                />
                <ContextMenuCardDelete
                  data-cy="CardDeckDetailContextMenu-CardDelete"
                  onDelete={() => {
                    driver.closeContextMenu();
                    driver.updateCardDelete();
                    driver.openModal("cardDelete");
                  }}
                />
              </>
            ) : (
              <>
                {!driver.isAutoplayedCard || driver.isPersonalizeCard ? (
                  <ContextMenuCardSave
                    data-cy="CardDeckDetailContextMenu-CardSave"
                    // Currently, this function has no effect, will add later
                    onSave={() => {
                      driver.cardRepostIdUpdate();
                      driver.closeContextMenu();
                    }}
                  />
                ) : null}
                <ContextMenuCardShare
                  data-cy="CardDeckDetailContextMenu-CardShare"
                  onShare={() => {
                    driver.toggleShareActive();
                    driver.closeContextMenu();
                  }}
                />
                {!driver.isAutoplayedCard || driver.isPersonalizeCard ? (
                  <ContextMenuCardReport
                    data-cy="CardDeckDetailContextMenu-CardReport"
                    onReport={() => {
                      driver.closeContextMenu();
                      driver.openModal("cardReport");
                    }}
                  />
                ) : (
                  !driver.isPersonalizeCard && (
                    <ContextMenuCardDelete
                      data-cy="CardDeckDetailContextMenu-CardDelete"
                      onDelete={() => {
                        driver.closeContextMenu();
                        driver.updateCardDelete();
                        driver.openModal("cardDelete");
                      }}
                    />
                  )
                )}
              </>
            )}
          </>
        )}
      />
    );
  },
);
